.images{
    image-rendering: optimizeSpeed;          
  image-rendering: -moz-crisp-edges;      
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;       
  image-rendering: pixelated;               
  -ms-interpolation-mode: nearest-neighbor;   
}


.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}


.main-content-div{
  width: 100%;
  overflow: auto; 
  scroll-behavior: smooth; /* Add smooth scrolling */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; 
  scrollbar-width: none;  
  
}
.sign-in-label {
  color: #322E33 !important;
  text-align: center;
  font-family: Segoe UI;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
}
.login-options {
  color: #49454f;
  text-align: center;
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}
.create-new-account-button {
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  color: #000000;
}
.create-new-account-text {
  color: black;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  width: 100%;
  margin-top: 8px !important;
}
.email-label {
  /* width: 56.71px; */
  height: 18px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #353846;
  margin-bottom: 10px;
  /* margin-left: 20px; */
}
.textfield-text {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #7e7f86;
}
.sign-in-text {
  color: black;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  text-decoration-line: underline;
}
.sign-in-button {
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  color: #ffffff;
}
#signup-form .MuiInputBase-input {
  margin-top: 5px !important;
  border-color: #8b8b90;
  border: 1px solid !important;
  border-radius: 0.375rem !important;
  height: 45px !important;
  padding: 0px !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
#signup-form .MuiInput-underline:after {
  border-bottom: none !important;
}

#signup-form .MuiInput-underline:before {
  border-bottom: none !important;
}
.css-1fjvggn-MuiPaper-root-MuiAccordion-root:first-of-type {
  min-width: -moz-fit-content;
}
.css-1tq8nf4-MuiFormControl-root-MuiTextField-root input {
  text-align: center;
  height: 64px;
}
  
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    font-size: 12px;
  line-height: 16px;
  
    
  }
  #rowSelector {
    padding: .5rem .75rem; /* Adjust padding to match the buttons */
    border: 1px solid #d1d5db; /* Light gray border */
    border-radius: .25rem; /* Match your button's border-radius */
    background-color: white;
    cursor: pointer;
  }
  
  /* Style focus and hover states for better user experience */
  #rowSelector:focus {
    outline: none;
    border-color: #60a5fa; /* Use a color that matches your theme */
    box-shadow: 0 0 0 1px #60a5fa;
  }
  
  #rowSelector:hover {
    border-color: #93c5fd;
  }
  #rowSelector1 {
    padding: .5rem .75rem; /* Adjust padding to match the buttons */
    border: 1px solid #d1d5db; /* Light gray border */
    border-radius: .25rem; /* Match your button's border-radius */
    background-color: white;
    cursor: pointer;
  }
  
  /* Style focus and hover states for better user experience */
  #rowSelector1:focus {
    outline: none;
    border-color: #60a5fa; /* Use a color that matches your theme */
    box-shadow: 0 0 0 1px #60a5fa;
  }
  
  #rowSelector1:hover {
    border-color: #93c5fd;
  }